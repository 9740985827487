<template>
  <menuMobile></menuMobile>
  <!-- <cookiesComponent/> -->
  <header-component />
  <router-view />
  <footer-component />
</template>

<script>
import headerComponent from "@/components/header.vue"
import FooterComponent from "@/components/footer.vue"
import menuMobile from "@/components/mobile-menu.vue"
// import cookiesComponent from "./components/cookies-component.vue"

export default {
  name: 'App',
  components: {
    headerComponent,
    FooterComponent,
    menuMobile,
    // cookiesComponent,
  },
  mounted() {
    // this.createCookie();
  },
  beforeUnmount() {
    // window.onbeforeunload = () => null;
  },
  methods: {
    // getParameterByName(name, url) {
    //   if (!url) url = window.location.href;
    //   // eslint-disable-next-line no-useless-escape
    //   name = name.replace(/[\[\]]/g, "\\$&");
    //   let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    //     results = regex.exec(url);
    //   if (!results) return null;
    //   if (!results[2]) return "";
    //   return decodeURIComponent(results[2].replace(/\+/g, " "));
    // },
    // setCookieValues(cname, cvalue, exdays, cdomain) {
    //   let d = new Date();
    //   d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    //   let expires = "expires=" + d.toUTCString();
    //   document.cookie =
    //     cname +
    //     "=" +
    //     cvalue +
    //     ";" +
    //     expires +
    //     ";domain=" +
    //     cdomain +
    //     ";path=/;SameSite=Lax;Secure;";
    // },
    // createCookie() {
    //   let domain = window.location.host;
    //   domain = "." + domain.replace("www.", "");
    //   this.deleteAffiliateCookies(domain);
    //   if (this.getParameterByName("affiliateCode") != null) {
    //     this.setCookieValues(
    //       "affiliateCode",
    //       this.getParameterByName("affiliateCode"),
    //       30,
    //       domain
    //     );
    //   }
    //   if (this.getParameterByName("bannerCode") != null) {
    //     this.setCookieValues(
    //       "bannerCode",
    //       this.getParameterByName("bannerCode"),
    //       30,
    //       domain
    //     );
    //   }
    //   if (this.getParameterByName("campaign") != null) {
    //     this.setCookieValues(
    //       "campaign",
    //       this.getParameterByName("campaign"),
    //       30,
    //       domain
    //     );
    //   }
    // },
    // getCookie(cname) {
    //   let name = cname + "=";
    //   let decodedCookie = decodeURIComponent(document.cookie);
    //   let ca = decodedCookie.split(";");
    //   for (let i = 0; i < ca.length; i++) {
    //     let c = ca[i];
    //     while (c.charAt(0) == " ") {
    //       c = c.substring(1);
    //     }
    //     if (c.indexOf(name) == 0) {
    //       return c.substring(name.length, c.length);
    //     }
    //   }
    //   return "";
    // },
    // deleteAffiliateCookies(domain) {
    //   let affiliateCookie = this.getCookie("affiliateCode");
    //   if (affiliateCookie != "") {
    //     this.setCookieValues("affiliateCode", affiliateCookie, -1, domain);
    //   }
    // },
  },
}
</script>
